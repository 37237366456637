import React, { Component } from 'react'
import Swiper from 'react-id-swiper'
import 'react-id-swiper/lib/styles/css/swiper.css'

import urban1 from '../../images/urban1.jpg'
import urban2 from '../../images/urban2.jpg'
import urban3 from '../../images/urban3.jpg'
import urban4 from '../../images/urban4.jpg'
import urban5 from '../../images/urban5.jpg'
import urban6 from '../../images/urban6.jpg'
import urban7 from '../../images/urban7.jpg'
import urban8 from '../../images/urban8.jpg'
import urban9 from '../../images/urban9.jpg'
import urban10 from '../../images/urban10.jpg'
import urban11 from '../../images/urban11.jpg'
import urban12 from '../../images/urban12.jpg'
import urban13 from '../../images/urban13.jpg'
import urban14 from '../../images/urban14.jpg'




export default class urbanpanelSlider extends Component {
	render() {
		const params = {
			loop: true,
			effect: 'fade',
			pagination: {
				el: '.swiper-pagination',
			},
			clickable: true,
			autoplay: {
				delay: 2500,
				disableOnInteraction: false,
			},
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
		}

		return (
			<Swiper {...params}>
				<div>
					<img
						src={urban1}
						className="img-fluid"
						alt="urbanPanel"
					/>
				</div>
				<div>
					<img
						src={urban2}
						className="img-fluid"
						alt="urbanPanel"
					/>
				</div>

				<div>
					<img
						src={urban3}
						className="img-fluid"
						alt="urbanPanel"
					/>
				</div>

				<div>
					<img
						src={urban4}
						className="img-fluid"
						alt="urbanPanel"
					/>
				</div>
				<div>
					<img
						src={urban5}
						className="img-fluid"
						alt="urbanPanel"
					/>
				</div>

				<div>
					<img
						src={urban6}
						className="img-fluid"
						alt="urbanPanel"
					/>
				</div>

				
				<div>
					<img
						src={urban7}
						className="img-fluid"
						alt="urbanPanel"
					/>
				</div>
				<div>
					<img
						src={urban8}
						className="img-fluid"
						alt="urbanPanel"
					/>
				</div>
				<div>
					<img
						src={urban9}
						className="img-fluid"
						alt="urbanPanel"
					/>
				</div>
				<div>
					<img
						src={urban10}
						className="img-fluid"
						alt="urbanPanel"
					/>
				</div>
				<div>
					<img
						src={urban11}
						className="img-fluid"
						alt="urbanPanel"
					/>
				</div>
						<div>
					<img
						src={urban12}
						className="img-fluid"
						alt="urbanPanel"
					/>
				</div>
				<div>
					<img
						src={urban13}
						className="img-fluid"
						alt="urbanPanel"
					/>
				</div>		
					<div>
					<img
						src={urban14}
						className="img-fluid"
						alt="urbanPanel"
					/>
				</div>{/*<div>
					<img
						src={campaignSlide12}
						className="img-fluid"
						alt="urbanPanel"
					/>
				</div><div>
					<img
						src={campaignSlide15}
						className="img-fluid"
						alt="urbanPanel"
					/>
				</div><div>
					<img
						src={campaignSlide16}
						className="img-fluid"
						alt="urbanPanel"
					/>
				</div><div>
					<img
						src={campaignSlide17}
						className="img-fluid"
						alt="urbanPanel"
					/>
				</div><div>
          <img
            src={campaignSlide18}
            className="img-fluid"
            alt="urbanPanel"
          />
        </div>*/}
			</Swiper>
		)
	}
}
