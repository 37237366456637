import React from 'react'
import Layout from '../layouts'
// import { Table } from 'reactstrap';
import Contact from '../components/contact/contact.js'
import UrbanPanelSlider from '../components/hero/urbanpanelSlider.js'
class UrbanPanelPage extends React.Component {
	render() {
		return (
			<Layout head={{ title: 'Customized PSA Campaigns' }}>
				<section id="lunchtruck" className="text-center">
					<div className="container">
						<div className="row">
							<div className="col">
								<h1 className="section-title">Urban Panel</h1>
								<p className="section-p">
									Many congested urban communities in large Metropolitan cities have limited wall and window space. Trimex Outdoor Storefront Advertising Network offers different solutions to fit your target market.  
								</p>
								<p>Our Urban Panel Billboard network features two sizes, (One Sheet) 30 x 46 or (Two) 18 x 24 posters. Urban Panels are ideal for convenience stores, grocery markets, corner deli’s, beauty salons, barbershops, bakeries, laundromats, restaurants and more. This medium includes our countertop and brochure box instore displays at no extra cost.</p>
								<UrbanPanelSlider />
							</div>
						</div>
					</div>
				</section>
				<Contact />
			</Layout>
		)
	}
}
export default UrbanPanelPage
